<template>
    <div class="-mx-4 p-4 bg-gray-50">
        <div class="flex flex-row justify-between items-end">
            <div v-if="!timelineLoading" class="flex flex-row items-end gap-4">
                <TimelineIntervalChanger
                    :current-quarter="quarter"
                    :current-year="year"
                    :minimal-year="MINIMAL_YEAR"
                    :maximal-year="MAXIMAL_YEAR"
                    :warning-no-allocations="warningNoAllocations"
                    :is-time-span-modified="isTimeSpanModified"
                    @change-quarter="onChangeQuarter"
                    @change-year="onChangeYear"
                    @click-previous-quarter="onClickPreviousQuarter"
                    @click-next-quarter="onClickNextQuarter"
                />

                <TimelineOptionSwitch
                    name="timeline-locations-display"
                    :active-index="activeLocationsDisplayIndex"
                    :label="$t('timeline-locations-display-label')"
                    @change-option="(index) => setActiveLocationsDisplayIndex(index)"
                >
                    <TimelineOptionSwitchEntry
                        :icon="markRaw(GroupedEntriesIcon)"
                        :index="0"
                        :label="$t('timeline-locations-display-sections-label')"
                    />
                    <TimelineOptionSwitchEntry
                        :icon="markRaw(NestedEntriesIcon)"
                        :index="1"
                        :label="$t('timeline-locations-display-hierarchy-label')"
                    />
                </TimelineOptionSwitch>
            </div>

            <VariantSelectionDropdown
                v-if="!timelineLoading && mappedCurrentVariant"
                :drop-down-status="dropDownStatus"
                :variants-ref="variantsRef"
                :visible-variants="visibleVariants"
                :default-active-variants="defaultActiveVariants"
                :selection-panel-variants="selectionPanelVariants"
                :highlighted-variant="highlightedVariant"
                :highlighted-variant-id="highlightedVariantId"
                :mapped-current-variant="mappedCurrentVariant"
                :allocation-type="allocationType"
                :object-id="projectId"
                :set-variants-ref="setVariantsRef"
                :set-visible-variants="setVisibleVariants"
                :set-highlighted-variant-id="setHighlightedVariantId"
                :on-delete-filter="onDeleteFilter"
            />
        </div>

        <Message v-if="timelineLoading" status="info">
            {{ $t('loading-timeline-data') }}
        </Message>

        <Message v-if="!timelineLoading && timelineError" status="error" :headline="$t('error-fetching-timeline-data')">
            {{ timelineError }}
        </Message>

        <Timeline
            v-if="!timelineLoading && editableVariant && site"
            class="mt-4"
            :site-name="site.nameShort"
            :site-link-target="routeForLocation('Site', site.id)"
            :start-date-time="startDateTime"
            :end-date-time="endDateTime"
            :previous-month-button="previousMonthButton"
            :next-month-button="nextMonthButton"
            :locations="locations"
            :allocations="filteredAllocations"
            :external-events="externalEvents"
            :sections="showSections ? sections : undefined"
            :allocation-variant-id="editableVariant.id"
            :current-allocation-variant-label="editableVariantLabel"
            :has-create-buttons="Boolean(highlightedVariant)"
            @toggle-section-expanded="toggleSectionExpanded"
            @create-allocation="onCreateAllocation"
            @delete-allocation="onDeleteAllocation"
            @update-phases="onUpdatePhases"
            @expand-to-previous-month="onExpandToPreviousMonth"
            @expand-to-next-month="onExpandToNextMonth"
        >
            <template #site-icon>
                <TerrainIcon class="size-5" :title="$t('site-icon-alt')" />
            </template>
        </Timeline>
    </div>
</template>

<script setup lang="ts">
import { useCreateAllocation } from '@/allocation/composables/useCreateAllocation';
import { useDeleteAllocation } from '@/allocation/composables/useDeleteAllocation';
import { useUpdateAllocations } from '@/allocation/composables/useUpdateAllocations';
import { useVariantSelectionPanel } from '@/components/AllocationVariantSelectionPanel/composables/useVariantSelectionPanel';
import GroupedEntriesIcon from '@/components/Icon/GroupedEntriesIcon.vue';
import NestedEntriesIcon from '@/components/Icon/NestedEntriesIcon.vue';
import TerrainIcon from '@/components/Icon/TerrainIcon.vue';
import Message from '@/components/Message/Message.vue';
import { useSections } from '@/components/Timeline/composables/useSections';
import { useTimelineLocations } from '@/components/Timeline/composables/useTimelineLocations';
import { sortByDateAsc, useTimelineRange } from '@/components/Timeline/composables/useTimelineRange';
import { useTimelineRouteParams } from '@/components/Timeline/timeline';
import { ExternalEvent } from '@/components/Timeline/Timeline.types';
import Timeline from '@/components/Timeline/Timeline.vue';
import {
    MAXIMAL_YEAR,
    MINIMAL_YEAR,
    useTimelineIntervalChanger,
} from '@/components/TimelineIntervalChanger/composables/useTimelineIntervalChanger';
import TimelineIntervalChanger from '@/components/TimelineIntervalChanger/TimelineIntervalChanger.vue';
import { useTimelineOptionsSwitch } from '@/components/TimelineOptionSwitch/composables/useTimlineOptionSwitch';
import TimelineOptionSwitch from '@/components/TimelineOptionSwitch/TimelineOptionSwitch.vue';
import TimelineOptionSwitchEntry from '@/components/TimelineOptionSwitch/TimelineOptionSwitchEntry.vue';
import { GetProjectVariantForTimelineDocument } from '@/generated/graphql';
import { routeForLocation } from '@/location/location';
import { $t } from '@/plugins/fluent';
import { useProjectAllocations } from '@/project/composables/useProjectAllocations';
import { useProjectTimelineData } from '@/project/composables/useProjectTimelineData';
import VariantSelectionDropdown from '@/variant/components/VariantSelectionDropdown.vue';
import { useHeadSafe } from '@unhead/vue';
import { DateTime } from 'luxon';
import { computed, markRaw } from 'vue';

const props = defineProps<{ projectId: string }>();
const projectId = computed(() => props.projectId);
const allocationType = 'Project';

const {
    variantsRef,
    setVariantsRef,
    loading: timelineLoading,
    error: timelineError,
    onResult: onTimelineResult,
    site,
    project,
    rooms,
    areas,
    usages,
} = useProjectTimelineData(projectId);

const externalEvents: ExternalEvent[] = [];

const { activeLocationsDisplayIndex, showSections, setActiveLocationsDisplayIndex } = useTimelineOptionsSwitch();

const { getLocations } = useTimelineLocations(site, rooms, areas, showSections);
const locations = computed(() => getLocations().value);
const executionYear = computed(() => project.value?.executionYear ?? DateTime.now().year);

const {
    dropDownStatus,
    visibleVariants,
    defaultActiveVariants,
    editableVariant,
    editableVariantLabel,
    highlightedVariantId,
    highlightedVariant,
    mappedCurrentVariant,
    selectionPanelVariants,
    setVisibleVariants,
    setHighlightedVariantId,
    onDeleteFilter,
} = useVariantSelectionPanel(variantsRef, allocationType, projectId);

const { visibleIdsFromRoute, highlightedIdFromRoute } = useTimelineRouteParams(variantsRef);

onTimelineResult(() => {
    if (visibleIdsFromRoute.value.length > 0) {
        setVisibleVariants(visibleIdsFromRoute.value);
    } else {
        setVisibleVariants(defaultActiveVariants.value.map((v) => v.id));
    }
    setHighlightedVariantId(highlightedIdFromRoute.value);
});

const label = computed(() => project.value?.nameShort ?? '');

const { filteredAllocations } = useProjectAllocations(
    variantsRef,
    visibleVariants,
    allocationType,
    label,
    highlightedVariantId,
    projectId,
);

const timelineStart = computed(() => {
    if (filteredAllocations.value && filteredAllocations.value.length > 0) {
        const allocationStartMin = filteredAllocations.value.toSorted((a, b) =>
            sortByDateAsc(a.startDateTime.toString(), b.startDateTime.toString()),
        )[0].startDateTime;
        return allocationStartMin.minus({ days: 2 });
    }
    if (executionYear.value) {
        return DateTime.fromISO(executionYear.value.toString());
    }
    return DateTime.now();
});

const {
    startDateTime,
    endDateTime,
    isTimeSpanModified,
    quarter,
    year,
    previousMonthButton,
    nextMonthButton,
    onChangeQuarter,
    onChangeYear,
    onClickPreviousQuarter,
    onClickNextQuarter,
    onExpandToPreviousMonth,
    onExpandToNextMonth,
} = useTimelineRange(timelineStart);

const { sections, toggleSectionExpanded } = useSections();
const { onCreateAllocation } = useCreateAllocation(
    variantsRef,
    setVariantsRef,
    editableVariant,
    usages,
    allocationType,
);
const { onDeleteAllocation } = useDeleteAllocation(variantsRef, setVariantsRef, allocationType);

const allocationsCount = computed(() => filteredAllocations.value.length);

const { warningNoAllocations } = useTimelineIntervalChanger(timelineLoading, allocationsCount);

const { onUpdatePhases } = useUpdateAllocations(
    variantsRef,
    setVariantsRef,
    usages,
    GetProjectVariantForTimelineDocument,
);

useHeadSafe({
    title: () =>
        `${$t('overview')} - ${project.value?.nameShort ?? ''} - ${project.value?.series?.nameShort ?? ''} - ${$t(
            'project-wiki',
        )}`,
});
</script>
