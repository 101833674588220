const BACKEND_URL = import.meta.env.BACKEND_URL.replace(/\/$/, '');

export type Config = {
    debug: boolean;
    envName: string;
    showEnvName: boolean;
    graphqlUrl: string;
    versionJsonUrl: string;
    sentryDsn: string;
    s3PublicUrl: string;
};

export const config: Config = {
    debug: String(import.meta.env.DEBUG).toLowerCase() === 'true',
    envName: import.meta.env.ENV_NAME,
    showEnvName: String(import.meta.env.SHOW_ENV_NAME).toLowerCase() === 'true',
    graphqlUrl: `${BACKEND_URL}/graphql`,
    versionJsonUrl: `${BACKEND_URL}/version.json`,
    sentryDsn: import.meta.env.SENTRY_DSN,
    s3PublicUrl: import.meta.env.S3_PUBLIC_URL.replace(/\/$/, ''),
};
